<template>
  <div>
    <div class="pb-6 border-b border-blue-200 mb-6">
      <div class="text-sm text-blue-400 mb-6">Bank Statement</div>
<!-- 
      <verification-statement
        :available-options="availableVerificationOptions"
        class="mb-6"
      />
 -->
      <template v-if="bankStatements.length">
        <div v-if="isMBSOrOkra || isMono || isUSSD || isMobile" class="mb-4">
          <span class="text-lg text-blue-400 font-semibold"
            >Verified with
            {{ lastBankStatement.url.toUpperCase() }}</span
          >
        </div>
        <div v-if="!isMBSOrOkra && !isMono && !isUSSD && !isMobile">
          <a
            :href="lastBankStatement.url"
            target="_blank"
            class="btn btn-blue mb-2"
          >
            View Bank Statement
          </a>
          <div class="text-sm text-blue-400 font-light">
            Last uploaded at
            <b class="text-blue-600">{{
              lastBankStatement.created_at
                | dateFormat("D M dd, Y h:ia")
            }}</b>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="alert alert-gray-soft mb-5">
          <span class="alert-icon">!</span>
          <span>Bank Statement Not Uploaded.</span>
        </div>
        <button
          type="button"
          class="btn btn-green-soft mb-2 mr-5"
          :disabled="blankBankStatementForm.loading"
          @click="submitBlankBankStatement"
        >
          <sm-loader
            v-if="blankBankStatementForm.loading"
            class="sm-loader-white"
          />
          <span v-else>Skip</span>
        </button>
      </template>
      <button
        type="button"
        class="btn btn-blue mt-2 mb-2"
        @click.prevent="$refs.uploadBankStatement.open()"
      >
        Upload
      </button>
    </div>

    <div class="pb-6 border-b border-blue-200 mb-6">
      <div class="text-sm text-blue-400 mb-6">Government Issued Id</div>

      <template v-if="govtIds.length">
        <a
          :href="lastGovtId.url"
          target="_blank"
          class="btn btn-blue mb-2"
        >
          View Govt Id
        </a>
        <div class="text-sm text-blue-400 font-light">
          Last uploaded at
          <b class="text-blue-600">{{
            lastGovtId.created_at | dateFormat("D M dd, Y h:ia")
          }}</b>
        </div>
      </template>

      <template v-else>
        <div class="alert alert-gray-soft mb-10">
          <span class="alert-icon">!</span>
          <span>Govt Id Not Uploaded.</span>
        </div>
      </template>
      <button
        type="button"
        class="btn btn-blue mt-2 mb-2"
        @click.prevent="$refs.uploadGovtId.open()"
      >
        Upload
      </button>
    </div>

    <div class="pb-6 border-b border-blue-200 mb-6">
      <div class="text-sm text-blue-400 mb-6">Work Id</div>

      <template v-if="workIds.length">
        <a
          :href="lastWorkId.url"
          target="_blank"
          class="btn btn-blue mb-2"
        >
          View Work Id
        </a>
        <div class="text-sm text-blue-400 font-light">
          Last uploaded at
          <b class="text-blue-600">{{
            lastWorkId.created_at | dateFormat("D M dd, Y h:ia")
          }}</b>
        </div>
      </template>

      <template v-else>
        <div class="alert alert-gray-soft mb-10">
          <span class="alert-icon">!</span>
          <span>Work Id Not Uploaded.</span>
        </div>
      </template>

      <button
        type="button"
        class="btn btn-blue mt-2 mb-2"
        @click.prevent="$refs.uploadWorkId.open()"
      >
        Upload
      </button>
    </div>

    <div class="pb-6 border-b border-blue-200 mb-6">
      <div class="text-sm text-blue-400 mb-6">Credit Report</div>

      <template v-if="creditReports.length">
        <a
          :href="lastCreditReport.url"
          target="_blank"
          class="btn btn-blue mb-2"
        >
          View Credit Report
        </a>
        <div class="text-sm text-blue-400 font-light">
          Last uploaded at
          <b class="text-blue-600">{{
            lastCreditReport.created_at | dateFormat("D M dd, Y h:ia")
          }}</b>
        </div>
      </template>

      <template v-else>
        <div class="alert alert-gray-soft mb-10">
          <span class="alert-icon">!</span>
          <span>Credit Report Not Uploaded.</span>
        </div>
      </template>

      <button
        type="button"
        class="btn btn-blue mt-2 mb-2"
        @click.prevent="$refs.uploadCreditReport.open()"
      >
        Upload
      </button>
    </div>

    <div class="pb-6 border-b border-blue-200 mb-6">
      <div class="text-sm text-blue-400 mb-6">Custom Documents</div>

      <template v-if="customDocuments.length">
        <template v-for="(doc, index) in customDocuments">
          <div
            :key="index"
            class="border border-blue-200 flex flex-wrap items-center p-3 pl-0 pb-0 rounded mb-2"
          >
            <div class="mb-3 ml-3 mr-auto">
              <div class="font-medium">{{ doc.filename }}</div>
              <div class="text-sm text-blue-400 font-light">
                <span>Uploaded at: </span>
                <span class="font-medium text-blue-600">
                  {{ doc.created_at | dateFormat("D M dd, Y h:ia") }}
                </span>
              </div>
            </div>
            <div class="mb-3 ml-3">
              <a
                :href="doc.url"
                target="_blank"
                class="btn btn-blue btn-sm"
              >
                View Document
              </a>
            </div>
          </div>
        </template>
      </template>

      <template v-else>
        <div class="alert alert-gray-soft mb-10">
          <span class="alert-icon">!</span>
          <span>No Custom Documents Uploaded.</span>
        </div>
      </template>

      <button
        type="button"
        class="btn btn-blue mt-2 mb-2"
        @click.prevent="$refs.uploadCustomDocument.open()"
      >
        Upload
      </button>
    </div>

    <modal className="w-11/12 sm:w-400px rounded" ref="uploadBankStatement">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Upload Bank Statement
        </h1>

        <div class="mt-10">
          <template v-if="getFormError(bankStatementForm)">
            <div class="alert alert-red mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(bankStatementForm) }}</span>
            </div>
          </template>

          <div class="flex flex-col">
            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="bankStatementForm.data.bank_statement.value"
              accept=".pdf"
            />
            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('bank_statement', bankStatementForm)"
            >
              {{ getFirstError("bank_statement", bankStatementForm) }}
            </div>

            <button
              type="button"
              class="btn btn-blue mt-32"
              @click.prevent="submitBankStatement"
              :disabled="bankStatementForm.loading"
            >
              <sm-loader v-if="bankStatementForm.loading" class="sm-loader-white" />
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal className="w-11/12 sm:w-400px rounded" ref="uploadGovtId">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Upload Govt Id
        </h1>

        <div class="mt-10">
          <template v-if="getFormError(govtIdForm)">
            <div class="alert alert-red mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(govtIdForm) }}</span>
            </div>
          </template>

          <div class="flex flex-col">
            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="govtIdForm.data.govt_id.value"
              accept=".jpg, .jpeg"
            />
            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('govt_id', govtIdForm)"
            >
              {{ getFirstError("govt_id", govtIdForm) }}
            </div>

            <button
              type="button"
              class="btn btn-blue mt-32"
              @click.prevent="submitGovtId"
              :disabled="govtIdForm.loading"
            >
              <sm-loader v-if="govtIdForm.loading" class="sm-loader-white" />
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal className="w-11/12 sm:w-400px rounded" ref="uploadWorkId">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Upload Work Id
        </h1>

        <div class="mt-10">
          <template v-if="getFormError(workIdForm)">
            <div class="alert alert-red mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(workIdForm) }}</span>
            </div>
          </template>

          <div class="flex flex-col">
            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="workIdForm.data.work_id.value"
              accept=".jpg, .jpeg, .pdf"
            />
            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('work_id', workIdForm)"
            >
              {{ getFirstError("work_id", workIdForm) }}
            </div>

            <button
              type="button"
              class="btn btn-blue mt-32"
              @click.prevent="submitWorkId"
              :disabled="workIdForm.loading"
            >
              <sm-loader v-if="workIdForm.loading" class="sm-loader-white" />
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal className="w-11/12 sm:w-400px rounded" ref="uploadCreditReport">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Upload Credit Report
        </h1>

        <div class="mt-10">
          <template v-if="getFormError(creditReportForm)">
            <div class="alert alert-red mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(creditReportForm) }}</span>
            </div>
          </template>

          <div class="flex flex-col">
            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="creditReportForm.data.credit_report.value"
              accept=".xlsv, .xlsx, .csv, .tsv"
            />
            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('credit_report', creditReportForm)"
            >
              {{ getFirstError("credit_report", creditReportForm) }}
            </div>

            <button
              type="button"
              class="btn btn-blue mt-32"
              @click.prevent="submitCreditReport"
              :disabled="creditReportForm.loading"
            >
              <sm-loader v-if="creditReportForm.loading" class="sm-loader-white" />
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal className="w-11/12 sm:w-400px rounded" ref="uploadCustomDocument">
      <div class="lg:-mx-8">
        <h1 class="text-18 text-left font-bold mb-2">
          Upload Custom Document
        </h1>

        <div class="mt-10">
          <template v-if="getFormError(customDocumentForm)">
            <div class="alert alert-red mb-10">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(customDocumentForm) }}</span>
            </div>
          </template>

          <div class="flex flex-col">
            <form-group
              v-model="customDocumentForm.data.title.value"
              :form="customDocumentForm"
              :data="customDocumentForm.data.title"
              name="title"
            >
              Document Title
            </form-group>

            <dropzone
              class="mb-0 mr-2 w-full"
              v-model="customDocumentForm.data.file.value"
            />
            <div
              class="form-group-2-text text-red-500 mb-2"
              v-if="getFirstError('file', customDocumentForm)"
            >
              {{ getFirstError("file", customDocumentForm) }}
            </div>
            <button
              type="button"
              class="btn btn-blue mt-32"
              @click.prevent="submitCustomDocument"
              :disabled="customDocumentForm.loading"
            >
              <sm-loader v-if="customDocumentForm.loading" class="sm-loader-white" />
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </modal>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">
      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Document Uploaded Successfully
      </div>

      <button type="button" class="btn btn-blue" @click.prevent="$refs.successModal.close">
        Okay
      </button>
    </modal>
  </div>
</template>
<script>
export default {
  props: {
    selectedUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bankStatementForm: this.$options.basicForm([
        { name: "bank_statement", value: null },
      ]),
      blankBankStatementForm: this.$options.basicForm([]),
      creditReportForm: this.$options.basicForm([
        { name: "credit_report", value: null },
      ]),
      customDocumentForm: this.$options.basicForm(
        ['title', { name: "file", value: null }],
        { preview: null }
      ),
      govtIdForm: this.$options.basicForm([
        { name: "govt_id", value: null },
      ]),
      workIdForm: this.$options.basicForm([
        { name: "work_id", value: null },
      ]),
    };
  },
  computed: {
    availableVerificationOptions() {
      const options = [];
      if (!this.workEmailVerified) {
        options.push('work_email');
      }
      if (!this.bankStatementVerified) {
        options.push('bank_statement');
      }
      return options;
    },
    bankStatements() {
      return this.selectedUser?.documents.filter(
        (doc) => doc.type === "bank_statement"
      );
    },
    bankStatementVerified() {
      return this.bankStatements?.length;
    },
    creditReports() {
      return this.selectedUser?.documents.filter(
        (doc) => doc.type === "credit_report"
      );
    },
    customDocuments() {
      return this.selectedUser?.documents.filter(
        (doc) => doc.type === "custom"
      );
    },
    govtIds() {
      return this.selectedUser?.documents.filter(
        (doc) => doc.type === "govt_id"
      );
    },
    isMBSOrOkra() {
      return (
        this.lastBankStatement?.url === "mbs" ||
        this.lastBankStatement?.url === "okra"
      );
    },
    isMobile() {
      return this.lastBankStatement?.url === 'mobile' 
    },
    isMono() {
      return this.lastBankStatement?.url === "mono"
    },
    isUSSD() {
      return this.lastBankStatement?.url === 'ussd' 
    },
    lastBankStatement() {
      return this.bankStatements[this.bankStatements.length - 1];
    },
    lastCreditReport() {
      return this.creditReports[this.creditReports.length - 1];
    },
    lastGovtId() {
      return this.govtIds[this.govtIds.length - 1];
    },
    lastWorkId() {
      return this.workIds[this.workIds.length - 1];
    },
    workEmailVerified() {
      return this.selectedUser?.official_email;
    },
    workIds() {
      return this.selectedUser?.documents.filter(
        (doc) => doc.type === "work_id"
      );
    },
  },
  beforeMount() {},
  methods: {
    async submitBankStatement() {
      if (!this.validateForm(this.bankStatementForm)) {
        return false;
      }
      const data = new FormData();
      data.append(
        "bank_statement",
        this.bankStatementForm.data.bank_statement.value
      );

      this.bankStatementForm.setLoading();
      this.bankStatementForm.error = false;

      await this.sendRequest('admin.users.documents.bankStatement', this.selectedUser?.id, {
        data,
        success: (response) => {
          this.$emit("updated", response.data.user);
          this.$refs.uploadBankStatement.close();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.bankStatementForm.error = error;
          this.mapFormErrors(
            this.bankStatementForm,
            error?.response?.data?.errors
          );
        },
      });

      this.bankStatementForm.setLoading(false);
    },

    async submitBlankBankStatement() {
      this.blankBankStatementForm.setLoading(true);
      await this.sendRequest('admin.users.documents.blankBankStatement', this.selectedUser?.id, {
        success: () => {
          this.$emit("success");
        },
        error: (error) => {
          console.log(error);
        },
      });
      this.blankBankStatementForm.setLoading(false);
    },

    async submitCreditReport() {
      if (!this.validateForm(this.creditReportForm)) {
        return false;
      }

      const data = new FormData();
      data.append("file", this.creditReportForm.data.credit_report.value);

      this.creditReportForm.error = null;
      this.creditReportForm.setLoading();

      await this.sendRequest('admin.users.documents.creditReport', this.selectedUser?.id, {
        data,
        success: (response) => {
          this.$emit("updated", response.data.user);
          this.$refs.uploadCreditReport.close();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.creditReportForm.error = error;
          this.mapFormErrors(
            this.creditReportForm,
            error?.response?.data?.errors
          );
        },
      });

      this.creditReportForm.setLoading(false);
    },

    async submitCustomDocument() {
      if (!this.validateForm(this.customDocumentForm)) {
        return false;
      }

      const data = new FormData();
      data.append("title", this.customDocumentForm.data.title.value);
      data.append("file", this.customDocumentForm.data.file.value);

      this.customDocumentForm.error = null;
      this.customDocumentForm.setLoading();

      await this.sendRequest('admin.users.documents.custom', this.selectedUser?.id, {
        data,
        success: (response) => {
          this.$emit("updated", response.data.user);
          this.$refs.uploadCustomDocument.close();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.customDocumentForm.error = error;
          this.mapFormErrors(
            this.customDocumentForm,
            error?.response?.data?.errors
          );
        },
      });

      this.customDocumentForm.setLoading(false);
    },

    async submitGovtId() {
      if (!this.validateForm(this.govtIdForm)) {
        return false;
      }

      const data = new FormData();
      data.append("govt_id", this.govtIdForm.data.govt_id.value);

      this.govtIdForm.error = null;
      this.govtIdForm.setLoading();

      await this.sendRequest('admin.users.documents.govtId', this.selectedUser?.id, {
        data,
        success: (response) => {
          this.$emit("updated", response.data.user);
          this.$refs.uploadGovtId.close();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.govtIdForm.error = error;
          this.mapFormErrors(
            this.govtIdForm,
            error?.response?.data?.errors
          );
        },
      });

      this.govtIdForm.setLoading(false);
    },

    async submitWorkId() {
      if (!this.validateForm(this.workIdForm)) {
        return false;
      }

      const data = new FormData();
      data.append("work_id", this.workIdForm.data.work_id.value);

      this.workIdForm.error = null;
      this.workIdForm.setLoading();

      await this.sendRequest('admin.users.documents.workId', this.selectedUser?.id, {
        data,
        success: (response) => {
          this.$emit("updated", response.data.user);
          this.$refs.uploadWorkId.close();
          this.$refs.successModal.open();
        },
        error: (error) => {
          this.workIdForm.error = error;
          this.mapFormErrors(
            this.workIdForm,
            error?.response?.data?.errors
          );
        },
      });

      this.workIdForm.setLoading(false);
    },

  },
};
</script>